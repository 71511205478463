<template>
  <DataTable
    widget-classes="mb-5 mb-xl-8"
    :refs="refs"
    :colspan="7"
    :hasSearchFilter="true"
    :hasDateFilter="true"
    :hasStatusFilter="true"
    :hasHashId="true"
    :hasBulkAction="true"
    :hasExport="true"
    :addNew="addNew"
    :hasShowEntries="true"
    :lblSearch="$t('app.search_by_name_or_email')"
    :fields="{
      id: 'id',
      title: 'title',
      date: 'date',
      status: 'status',
    }"
    :hasUser="true"
    :image="$t('view.user')"
    :image_style="'width: 40px;height: 40px;border-radius: 50%'"
    :hasSubject="true"
    :subject="$t('view.subject')"
    :hasPrice="true"
    :price="$t('view.amount')"
    :hasStartDate="true"
    :start_date="$t('view.start_date')"
    :hasEndDate="true"
    :end_date="$t('view.end_date')"
    :hasActions="true"
    :hasDeleteButton="true"
    :actionable="true"
    :sorDataTable="true"
    v-on:hasAddNewPermission="handlePermissoinChange"
  >
  </DataTable>
</template>

<script>
import { defineComponent } from "vue";
import DataTable from "@/components/DataTable.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "List",
  components: {
    DataTable,
  },
  data() {
    return {
      //
      addNew: false,
      refs: "contracts",
    };
  },
  created() {
    setCurrentPageTitle(this.$t("nav.contracts"), "bx bx-file");
  },
  methods: {
    handlePermissoinChange(event) {
      this.addNew = event;
    },
  },
});
</script>
